import React from 'react';
import styled from 'styled-components';
import { isEqual } from 'lodash-es';

import { useAppSelector, useAppDispatch } from '@/store';
import { DataElementContext } from '@/page-components/common/DataElementContext';
import { processComponentProps } from '@/page-components/utils/processComponentProps';
import { fetchSources } from '@/store/slices/dataSources';

const DEBUG = true;

const useJackpotResolver = ({ group }: { group: string }) => {
  const cacheRef = React.useRef<any>(null);
  const requestedGames = React.useRef<boolean>(false);
  const jackpotsDataSource = useAppSelector(
    (state) => state.dataSources?.items?.[window.config?.dataSourceAllJackpots],
  );
  const jackpotGames = useAppSelector((state) => (group ? state.dataSources?.items?.[`jackpot_games_${group}`] : null));
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (group && !jackpotGames && !requestedGames.current) {
      const jackpot = jackpotsDataSource?.data?.find?.((item: any) => item.group === group);
      if (jackpot?.summedGroups == null) {
        requestedGames.current = true;
        dispatch(fetchSources({ ids: [`jackpot_games_${group}`] }));
      }
    }
  }, [group, jackpotsDataSource, jackpotGames]); // eslint-disable-line react-hooks/exhaustive-deps

  const state = React.useMemo(() => {
    let ret = null;

    if (jackpotsDataSource?.data) {
      const jackpot = jackpotsDataSource?.data?.find?.((item: any) => item.group === group);
      if (jackpot) {
        if (isEqual(cacheRef.current?.jackpot, jackpot) && isEqual(cacheRef.current?.games, jackpotGames?.data)) {
          DEBUG && console.log('Jackpot[useJackpotResolver] no change', group, jackpot, jackpotGames?.data);
          ret = cacheRef.current;
        } else {
          const newV: any = {};
          newV.jackpot = jackpot;
          newV.games = jackpotGames?.data;

          if (jackpot?.summedGroups?.length) {
            newV.summedGroups = jackpot?.summedGroups.map((group: any) => {
              const groupJackpot = jackpotsDataSource?.data?.find((item: any) => item.group === group);
              if (groupJackpot) {
                return groupJackpot;
              }
              return null;
            });
          }

          cacheRef.current = newV;
          ret = newV;
        }
      }
    }

    return ret;
  }, [group, jackpotsDataSource, jackpotGames]);

  return state;
};

type JackpotProps = {
  children: React.ReactNode;
  styleText: string;
  style?: React.CSSProperties;
  className: string;
  properties?: any;
};

const ModuleElementDiv = styled.div<{ $styleText: string }>((props) => props.$styleText);

const Jackpot = (componentProps: JackpotProps) => {
  let props = componentProps;

  const dataElementContext = React.useContext(DataElementContext);
  [props] = processComponentProps(props, dataElementContext);

  const group = props.properties?.group;

  const jackpotData: any = useJackpotResolver({ group });

  const contextValue = React.useMemo(() => {
    if (!jackpotData) {
      return null;
    }

    const result = { ...jackpotData.jackpot, games: jackpotData.games };

    if (jackpotData.summedGroups) {
      result.summedGroups = jackpotData.summedGroups;
    }

    return result;
  }, [jackpotData, dataElementContext, componentProps]); // eslint-disable-line react-hooks/exhaustive-deps

  DEBUG && console.log('Jackpot[contextValue]', contextValue);

  return (
    <ModuleElementDiv className={props.className ?? ''} $styleText={props.styleText} style={props.style}>
      <DataElementContext.Provider value={contextValue}>{componentProps.children}</DataElementContext.Provider>
    </ModuleElementDiv>
  );
};

export default React.memo(Jackpot);
